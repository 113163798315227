<template>
  <div class="card d-flex flex-row-fluid flex-center">
    <div class="card-body w-100 w-xl-700px px-9">
      <ValidationObserver ref="observerbusiness">
        <div class="current" data-kt-stepper-element="content">
          <div class="w-100">
            <div>
              <h2 class="fw-bolder text-dark">Business Info</h2>
            </div>
            <hr />
            <div class="row fv-row mb-10 fv-plugins-icon-container">
              <div class="row">
                <!-- <div

                class="col-md-6 col-sm-6 pb-5"
                v-if="show"
              >
                <label>CR #</label>
                  <input
                    name=""
                    maxlength="50"
                    placeholder="CR #"
                    v-model="businessInfo.CR_number"
                    class="form-control form-control-lg form-control-solid"
                  />
              </div> -->
                <div class="col-md-6 pb-5" v-if="accoutInfo.Account_type != 2">
                  <label
                    class="form-label required">{{ accoutInfo.Account_type == 2 ? 'Company Name' : accoutInfo.Account_type == 1 ? 'Supplier Name' : 'Organization Name' }}</label>
                  <ValidationProvider
                    :name="accoutInfo.Account_type == 2 ? 'Company Name' : accoutInfo.Account_type == 1 ? 'Supplier Name' : 'Organization Name'"
                    rules="required" v-slot="{ errors }">
                    <input name="" maxlength="50" :placeholder="accoutInfo.Account_type == 1 ? 'GlassHUB Supplier' : 'GlassHUB Company'"
                      class="form-control form-control-lg form-control-solid" v-model="businessInfo.Company_name"
                      :class="errors.length ? 'is-invalid custom-border' : ''" />
                    <span class="invalid-feedback" role="alert">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-6 pb-5" v-if="accoutInfo.Account_type != 2">
                  <label
                    class="form-label required">{{ accoutInfo.Account_type == 2 ? 'Company Name In Arabic' : accoutInfo.Account_type == 1 ? 'Supplier Name In Arabic' : 'Organization Name In Arabic' }}</label>
                  <ValidationProvider
                    :name="accoutInfo.Account_type == 2 ? 'Company Name In Arabic' : accoutInfo.Account_type == 1 ? 'Supplier Name In Arabic' : 'Organization Name'"
                    rules="required" v-slot="{ errors }">
                    <input name="" maxlength="50" :placeholder="accoutInfo.Account_type == 1 ? 'GlassHUB Supplier' : 'GlassHUB Company'"
                      class="form-control form-control-lg form-control-solid" v-model="businessInfo.Company_name_ar"
                      :class="errors.length ? 'is-invalid custom-border' : ''" />
                    <span class="invalid-feedback" role="alert">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>

                <div class="col-md-6 d-flex align-items-center mb-4">
                  <div class="row w-100">
                    <div v-if="!selected_file">
                      <!-- <ValidationProvider
                                            name="Logo"
                                            rules="image"
                                            v-slot="{ errors }"
                                            ref="provider"
                                        > -->
                      <label class="form-label required">Logo</label>
                      <input type="file" class="form-control form-control-lg form-control-solid" accept="image/*" id="logo_img"
                        @change="handleFileChange" />
                      <!-- <span class="invalid-feedback" role="alert">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider> -->
                    </div>

                    <div class="symbol symbol-70px symbol-lg-70px col-md-6 floa symbol-fixed position-relative" style="width: 100%" v-else>
                      <span class="cross-button" @click="ClearImage()" style="font-size: 18px; font-family: cursive">X</span>
                      <img :src="url" class="d-flex justify-content-end" alt="image" style="width: 122px; height: auto; margin: auto" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                class="
                  symbol symbol-70px symbol-lg-70px
                  col-md-6
                  floa
                  symbol-fixed
                  position-relative
                "
              >
                <img
                  src="/assets/img/organizations/media/logo-01.jpg"
                  class="d-flex justify-content-end"
                  alt="image"
                  style="width: 122px; height: auto"
                />
              </div> -->
              <!-- <div
                class="col-md-6 pb-5"
                v-if="accoutInfo.Account_type !=1"
              >
                <ValidationProvider
                  name="Organization Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label v-if="show" class="form-label required">{{$t('Organization')}} Name</label>
                  <label v-else class="form-label required">Company Name</label>
                  <input
                    type="text"
                    maxlength="50"
                    v-model="businessInfo.Company_name"
                    placeholder="GlassHUB Organization "
                    class="form-control form-control-lg form-control-solid"
                    :class="errors.length ? 'is-invalid custom-border' : ''"
                  />
                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div> -->

              <!-- <div
                class="col-md-6 pb-5"
                  v-if="accoutInfo.Account_type ==1"
              >
                  <label  class="form-label">Company Name</label>
                  <input
                    type="text"
                     maxlength="50"
                    v-model="businessInfo.Company_name"
                    placeholder="GlassHUB Organization "
                    class="form-control form-control-lg form-control-solid"
                  />
              </div> -->
              <!-- <div
                class="col-md-6 pb-5">
              <label  class="form-label required">Country</label>
                <select
                  class="form-select form-select-lg form-select-solid"
                  v-model="bank_country"
                  @change="selected_bank_country(bank_country)"
                >
                  <option value="">Select Country</option>
                  <option
                    v-for="(c, index) in getCoutryData"
                    :key="index"
                    :value="c"
                  >
                    {{ c.name }}
                  </option>
                </select>
              </div> -->
              <div v-if="accoutInfo.Account_type != 4" class="col-md-6 pb-5">
                <label class="required fs-6 fw-bold mb-2">Global Presence</label>
                <div>
                  <select class="form-select form-select-lg form-select-solid"
                    :class="{ 'is-invalid': did_add_country && selected_country.length == 0 }" v-model="country" @change="add_country(country)">
                    <option>Select Country</option>
                    <option v-for="(c, index) in getCoutryData" :key="index" :value="c">
                      {{ c.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback" role="alert" v-if="did_add_country && selected_country.length == 0">
                    Country field is required.
                  </div>
                </div>
                <div class="globalpresenes_added mt-5">
                  <p v-for="(s, index) in selected_country" :key="index" class="me-2">
                    <span @click="removeCArray(index)" class="pe-2">x</span>
                    {{ s.name }}
                  </p>
                </div>
              </div>

              <div class="col-md-6 pb-5">
                <label class="form-label required">Bank Name</label>
                <ValidationProvider name="Bank Name" rules="required" v-slot="{ errors }">
                  <input name="" type="text" maxlength="34" class="form-control form-control-lg form-control-solid" v-model="businessInfo.bank_name"
                    placeholder="Bank Number" :class="errors.length ? 'is-invalid custom-border' : ''" />
                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 pb-5">
                <label class="form-label required">Bank Account Name</label>
                <ValidationProvider name="Customer Name" rules="required" v-slot="{ errors }">
                  <input name="" type="text" maxlength="50" class="form-control form-control-lg form-control-solid"
                    v-model="businessInfo.bank_customer_name" placeholder="Account Name " :class="errors.length ? 'is-invalid custom-border' : ''" />
                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 pb-5">
                <label class="form-label required">Bank IBAN Number</label>
                <ValidationProvider name="Bank IBAN Number" rules="required" v-slot="{ errors }">
                  <the-mask name="" type="text" v-model="businessInfo.bank_iban" :maxlength="iban_length"
                    class="form-control form-control-lg form-control-solid" placeholder="IBAN Number"
                    :class="errors.length || !isValidIban() ? 'is-invalid custom-border' : ''" mask="AAXX XXXX XXXX XXXX XXXX XXXXXX" />
                  <span class="invalid-feedback" role="alert">
                    {{ isValidIban() ? '' : 'The Bank IBAN Number field is required.' }}
                    <!-- {{ errors[0] }} -->
                  </span>
                </ValidationProvider>
              </div>
              <div class="col-md-6 pb-5">
                <label v-if="show" class="form-label required">{{ $t('Organization') }} Size</label>
                <label v-else class="form-label required">Company Size</label>
                <ValidationProvider name="Company Size" rules="required" v-slot="{ errors }">
                  <select name="company_size" class="form-select form-select-lg form-select-solid" v-model="businessInfo.Company_size"
                    :class="errors.length ? 'is-invalid custom-border' : ''">
                    <option v-for="(s, index) in size_array" :key="index" :value="s.label">
                      {{ s.label }}
                    </option>
                  </select>
                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <!-- <div class="col-md-6 pb-5">
                <label class="form-label required">Start Date</label>
                <br />
                <ValidationProvider
                  name="Start Date"
                  rules="required"
                  v-slot="{ errors }"
                >
                 <input type="date"  v-model="businessInfo.Start_date"  format="MM-DD-YYYY" :max="minDate" name="uniquename"
                    class="form-select form-select-solid"
                    :class="errors.length ? 'is-invalid custom-border' : ''">

                  <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div> -->

              <div class="col-md-6 pb-5"></div>
              <div class="col-md-6 pb-5">
                <label class="form-label">Head Office Address</label>
                <label v-if="accoutInfo && accoutInfo.Account_type == 2" class="form-label">Company Address</label>
                <!-- <ValidationProvider
                  :name="
                    accoutInfo && accoutInfo.Account_type == 1
                      ? 'Head office Address'
                      : 'Address'
                  "
                  rules="required"
                  v-slot="{ errors }"
                > -->
                <!-- <textarea
                                        name=""
                                        placeholder="Office No, Lane No, Area, City, Country"
                                        class="form-control form-control-lg form-control-solid"
                                        @input="serch_head_offices"
                                        :class="
                                            errors.length
                                                ? 'is-invalid custom-border'
                                                : ''
                                        "
                                        v-model="
                                            businessInfo.Head_office_address
                                        "
                                    ></textarea> -->
                <GmapAutocomplete @place_changed="setPlace" placeholder="Office No, Lane No, Area, City, Country" ref="head_office"
                  class="form-control form-control-lg form-control-solid" @keyup="usePlace" />
                <!--  :class="!businessInfo.Head_office_address && iscalled ? 'is-invalid custom-border' : ''" -->
                <!-- <span class="invalid-feedback" role="alert">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider> -->
              </div>
              <div class="col-md-6 pb-5">
                <GmapMap :center="center" :zoom="7" map-type-id="terrain" style="width: 100%; height: 200px">
                  <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true"
                    @drag="updateCoordinates" @click="center = m.position" />
                </GmapMap>
              </div>
              <div>
                <div class="row" v-for="(b, index) in branchOffices" :key="index">
                  <div class="col-md-6 pb-5">
                    <label class="form-label">Branch Office Address </label>
                    <!-- <textarea
                                        v-model="b.Office_address"
                                        placeholder="Office No, Lane No, Area, City, Country"
                                        class="form-control form-control-lg form-control-solid"
                                    ></textarea> -->
                    <GmapAutocomplete autocomplete="off" @place_changed="setPlaceBranch($event, index)" @keyup="usePlaceBranch" ref="branch_office"
                      placeholder="Office No, Lane No, Area, City, Country" class="form-control form-control-lg form-control-solid" />
                    <div class="text-right mt-3">
                      <button class="btn btn-xs btn-primary" type="button" @click="addOffice" v-if="index == 0">
                        Add More +
                      </button>
                      <button v-else class="btn btn-xs badge-danger text-white" type="button" @click="removeOffice(index)">
                        Remove -
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6 pb-5">
                    <GmapMap :center="b.center" :zoom="7" map-type-id="terrain" style="width: 100%; height: 200px">
                      <GmapMarker :key="indexm" v-for="(m, indexm) in b.markers" :position="m.position" :clickable="true" :draggable="true"
                        @drag="updateCoordinatesbranch($event, index)" @click="center = m.position" />
                    </GmapMap>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pb-5">
                <label class="form-label">Website URL</label>
                <input type="url" v-model="businessInfo.Website_url" maxlength="250" placeholder="www.yourwebsite.com"
                  class="form-control form-control-lg form-control-solid" @blur="FatchWebLogo()" />
              </div>
              <div class="col-md-12 pb-5" v-for="(s, index) in socialGroup" :key="`so-${index}`">
                <label class="form-label">Social Media Links</label>
                <input v-model="s.url" maxlength="250" placeholder="Linkedin.com/yourcompany"
                  class="form-control form-control-lg form-control-solid" />
                <div class="text-right mt-3">
                  <button class="btn btn-xs btn-primary" :disabled="socialGroup.length > 5" type="button" @click="addSocial()" v-if="index == 0">
                    Add More +
                  </button>
                  <button class="btn btn-xs badge-danger text-white" type="button" @click="removeSocial(index)" v-else>
                    Remove -
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-12 pb-5" v-if="switch_show_components">
              <div class="d-flex">
                <div class="me-5">
                  <label class="fs-6 fw-bold form-label">Please accept our
                    <a href="javascript:;" @click="TremCondition(0)" class="cursor-pointer">terms and conditions</a>
                    to proceed?</label>
                </div>
                <label class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" @change="setterm" v-model="signUpForm.Term_accept" />
                </label>
              </div>
            </div>

            <div class="d-flex flex-stack">
              <div class="mr-2" v-if="!switch_show_components">
                <button type="button" class="btn btn-lg btn-light-primary me-3" @click="gopre()">
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                      <path
                        d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                        fill="black" />
                    </svg>
                  </span>
                  Back
                </button>
              </div>
              <div>
                <button type="button" class="btn btn-lg btn-primary" @click="gonext()">
                  Continue
                  <span class="svg-icon svg-icon-4 ms-1 me-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false"></script>
<script>
// import BankInput from "../../bankInput.vue";
import DatePicker from 'vue2-datepicker';
import { TheMask } from 'vue-the-mask';
import _ from 'lodash';
import 'vue2-datepicker/index.css';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import AxiosPlugin from 'vue-axios-cors';

const ibanLengths = {
  'AL': 28, 'AD': 24, 'AT': 20, 'AZ': 28, 'BH': 22, 'BE': 16, 'BA': 20, 'BR': 29,
  'BG': 22, 'CR': 21, 'HR': 21, 'CY': 28, 'CZ': 24, 'DK': 18, 'DO': 28, 'EE': 20,
  'FO': 18, 'FI': 18, 'FR': 27, 'GE': 22, 'DE': 22, 'GI': 23, 'GR': 27, 'GL': 18,
  'GT': 28, 'HU': 28, 'IS': 26, 'IE': 22, 'IL': 23, 'IT': 27, 'JO': 30, 'KZ': 20,
  'XK': 20, 'KW': 30, 'LV': 21, 'LB': 28, 'LI': 21, 'LT': 20, 'LU': 20, 'MK': 19,
  'MT': 31, 'MR': 27, 'MU': 30, 'MC': 27, 'MD': 24, 'ME': 22, 'NL': 18, 'NO': 15,
  'PK': 24, 'PS': 29, 'PL': 28, 'PT': 25, 'QA': 29, 'RO': 24, 'SM': 27, 'SA': 24,
  'RS': 22, 'SK': 24, 'SI': 19, 'ES': 24, 'SE': 24, 'CH': 21, 'TN': 24, 'TR': 26,
  'AE': 23, 'GB': 22, 'VG': 24, EG: 27
};

function modulo(aNumStr, aDiv) {
    let tmp = "";
    let i, r;

    for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        r = tmp % aDiv;
        tmp = r.toString();
    }

    return tmp / 1;
}

const validateIBAN = (iban, countryCode = null, expectedLength = null) => {
  // Temporary hotfix
  return true;

  if (iban.length <= 30) {
        //Move front 4 digits to the end
        let rearrange = iban.substring(4, iban.length) + iban.substring(0, 4);
        let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
        let alphaMap = {};
        let number = [];

        for(let idx in alphabet) {
          const value = alphabet[idx];
          alphaMap[value] = idx + 10;
        }

        for(let idx in alphabet) {
          const value = alphabet[idx];
          number[idx] = alphaMap[value] || value;
        }

        return modulo(number.join("").toString(), 97) === 1;
  } else return false;

  // console.log('#iban-validation#2:', {
  //   compared: ibanTools.validateIBAN(iban),
  //   iban, len, expectedLength
  // })
};

export default {
  props: {
    signUpForm: {
      type: Object,
    },
    accoutInfo: {
      type: Object,
    },
    file: {
      type: Object,
    },
    fileData: {
      type: Object,
    },
  },
  components: {
    DatePicker,
    // BankInput
    TheMask,
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL ?? 'http://localhost:8084',
      selectedBankCountry: 'SA',
      center: { lat: 10, lng: 10 },
      show: true,
      switch_show_components: false,
      did_add_country: false,
      markers: [],
      selected_country: [],
      iban_length: '29',
      iscalled: false,
      mask: '',
      dashboard_path: process.env.VUE_APP_DASHBOARD_PATH ?? '',
      country: '',
      bank_country: '',
      businessInfo: {},
      size_array: [
        { id: '1', label: '1-10 Employess' },
        { id: '2', label: '11-50 Employess' },
        { id: '3', label: '51-200 Employess' },
        { id: '4', label: '201-1000 Employess' },
        { id: '5', label: '1001+ Employess' },
      ],
      url: '',
      logo_exit: true,
      selected_file: '',
      branchOffices: [
        {
          RegD_id: 1,
          Office_address: '',
          Office_lat: '',
          Office_long: '',
          markers: [],
          center: { lat: 10, lng: 10 },
        },
      ],
      socialGroup: [
        {
          url: '',
        },
      ],
      minDate: '',
    };
  },
  computed: {
    ...mapGetters({
      getCoutryData: 'category/getCoutryData',
      getSelectedCountry: 'signup/getSelectedCountry',
      getCompanyLogo: 'signup/getCompanyLogo',
      getCompanyLogoInfo: 'signup/getCompanyLogoInfo',
    }),
  },
  watch: {
    getSelectedCountry() {
      this.selected_country = this.getSelectedCountry;
    },
    // getCompanyLogo(){
    //   console.log(this.getCompanyLogo,'watch')
    // }
  },
  methods: {
    ...mapActions({
      setCountry: 'category/setCountry',
      setSelectedCountry: 'signup/setSelectedCountry',
      updateCompanyLogo: 'signup/updateCompanyLogo',
    }),

    isValidIban() {
      let currentIbanValue = String(this?.businessInfo?.bank_iban ?? '').trim();

      // console.info({currentIbanValue, info: this.businessInfo});

      if (currentIbanValue.length <= 0) {
        return false;
      }

      return validateIBAN(currentIbanValue, this.selectedBankCountry);
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    //    async urlToObject(UserImage,name){
    //     const response = await this.$axios.get(UserImage,{ crossdomain: true })
    //     const blob = await response.blob();
    //     let file  = new File([blob],  name,{type:"image/png", lastModified:new Date().getTime(),});
    //      return file
    // },

    //  toDataUrl(url, callback) {
    //     var xhr = new XMLHttpRequest();
    //     xhr.onload = function() {
    //         var reader = new FileReader();
    //         reader.onloadend = function() {
    //             callback(reader.result);
    //         }
    //         reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.open('GET', url);
    //     xhr.withCredentials = false;
    //     xhr.responseType = 'blob';
    //     xhr.send();
    // },

    FatchWebLogo() {
      if (this.logo_exit) {
        axios.get(`${this.apiUrl}/api/general/web?Fetch_Date=` + this.businessInfo?.Website_url).then((response) => {
          if (response.data.type == 'success') {
            let UserImage = response.data.result;
            this.url = UserImage;
            let bas64 = this.dataURLtoFile(UserImage, 'company_logo');
            this.selected_file = bas64;
            this.fileData.Company_logo = this.selected_file;
            this.$emit('fileUpload', this.fileData);
          }
        });
      }
    },

    TremCondition(page_id) {
      let account_type = this.accoutInfo.Account_type;
      this.$router.push('/terms_and_conditions');
      this.$store.dispatch('signup/FatchTerm', { id: account_type, page_id: page_id });
    },

    setterm() {
      if (this.signUpForm.Term_accept) {
        this.setTermCondition();
      }
    },

    gopre() {
      this.$emit('gopre', 2);
    },
    addOffice() {
      this.branchOffices.push({
        RegD_id: 1,
        Office_address: '',
        Office_lat: '',
        Office_long: '',
        markers: [],
        center: { lat: 10, lng: 10 },
      });
    },
    removeOffice(index) {
      this.branchOffices.splice(index, 1);
    },
    addSocial() {
      this.socialGroup.push({
        url: '',
      });
    },
    removeSocial(index) {
      this.socialGroup.splice(index, 1);
    },
    serch_head_offices: _.debounce(function () {
      alert(12);
    }, 500),

    async gonext() {
      const isValid = await this.$refs.observerbusiness.validate();
      const isValidIbanNum = true === this.isValidIban();

      console.info({isValid, isValidIbanNum, selectedCountry: this.selectedBankCountry, iinfo: this.businessInfo});

      if (false === isValid || false === isValidIbanNum) {
        return;
      }

      // if(!this.businessInfo.Head_office_address){
      //     this.$notify({
      //     group: 'noti',
      //     title: 'Error',
      //     type:'error',
      //     text:'Please add HeadOffice Address'
      //   });
      //   return
      // }

      let enterbrnach = false;
      var self = this;

      this.branchOffices.map(function (value) {
        if (value.Office_address) {
          enterbrnach = true;
        }
      });

      // if(!enterbrnach){
      //   this.$notify({
      //           group: 'noti',
      //           title: 'Error',
      //           type:'error',
      //           text:'Please enter at least on branch office'
      //         });
      //         return
      // }
      this.setSelectedCountry(this.selected_country);
      let index = 1;
      var self = this;
      this.socialGroup.map(function (value) {
        if (index == 1) self.businessInfo.Social_media_url1 = value.url;
        else if (index == 2) self.businessInfo.Social_media_url2 = value.url;
        else if (index == 3) self.businessInfo.Social_media_url3 = value.url;
        else if (index == 4) self.businessInfo.Social_media_url4 = value.url;
        else if (index == 5) self.businessInfo.Social_media_url5 = value.url;
        else if (index == 6) self.businessInfo.Social_media_url6 = value.url;
        index++;
      });
      // this.businessInfo.Company_logo = null;
      let country_id = '';
      this.selected_country.map(function (value) {
        country_id += value.id + ',';
      });
      this.businessInfo.Country_id = country_id;
      this.branchOffices = this.branchOffices.map((data) => ({
        ...data,
        Office_address: data.Office_address ? data.Office_address : '',
        Office_lat: data.Office_lat ? data.Office_lat : 0,
        Office_long: data.Office_long ? data.Office_long : 0,
      }));
      this.signUpForm.RegistrationD[0] = this.businessInfo;
      // this.signUpForm.Company_logo = this.Company_logo;
      this.signUpForm.Company_logo = this.file;
      this.signUpForm.Office_Branches = this.branchOffices;
      // this.signUpForm.organization_name = this.businessInfo.Company_name;

      this.updateCompanyLogo(this.selected_file);
      // this.$store.dispatch('signup/setCompanyLogoInfo',{name:this.selected_file.name,url:this.url})
      this.$store.dispatch('signup/setCompanyLogoInfo', { name: this.file?.filename, url: this.url });
      //  if(this.iscalled || this.signUpForm.RegistrationD[0].Head_office_address==null){
      //   this.$notify({
      //           group: 'noti',
      //           title: 'Error',
      //           type:'error',
      //           text:'Please add valid HeadOffice Address'
      //         });
      //   return false
      //  }
      if (this.selected_file == '') {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'Logo is required',
        });
        return;
      }
      if (!(this.signUpForm.RegistrationD[0].Country_id || this.accoutInfo.Account_type == 4)) {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'Global Presence required',
        });
        return;
      }

      if (!this.signUpForm.Term_accept && this.switch_show_components == true) {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'Please Accept Terms Terms and Conditions',
        });
        return;
      }

      let data = {
        form: this.signUpForm,
        step: 4,
      };
      this.$emit('saveNext', data);
    },
    async ClearImage() {
      if (this.file?.filename) {
        try {
          // const apiUrl = process.env.VUE_APP_API_URL ?? 'http://localhost:8084';
          await axios.delete(`${this.apiUrl}/api/Signup/TempDocument/` + this.file?.filename, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          this.uploadStatus = 'File deleted successfully.';
        } catch (error) {
          console.error('Error uploading file:', error);
          this.uploadStatus = 'Error deleting file.';
        }
      }
      this.url = '';
      this.selected_file = '';
      this.file = null;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async urlToObject(UserImage, name) {
      const response = await fetch(UserImage);
      const blob = await response.blob();
      let file = new File([blob], name, { type: 'image/png', lastModified: new Date().getTime() });
      this.selected_file = file;
      return file;
    },

    async handleFileChange(e) {
      const fileSize = Math.floor(e.target?.files[0].size / 1000);
      if (fileSize > 2000) {
        this.$notify({
          group: 'noti',
          title: 'Error',
          type: 'error',
          text: 'file size exceed to 2MB',
        });
        e.target.value = '';
        return false;
      }
      this.logo_exit = false;
      // const { valid } = await this.$refs.provider.validate(e);
      // if (valid) {
      const file = e.target.files[0];
      this.selected_file = e.target.files[0];
      //  this.branchOffices.Company_logo
      // let data={
      //   type:'c_logo',
      //   data:this.selected_file
      // }

      const resFile = await this.triggerUpload(file);

      this.file = resFile;
      //  this.fileData.Company_logo=this.selected_file
      this.fileData.Company_logo = resFile?.fileurl;
      this.$emit('fileUpload', this.fileData);
      // this.getBase64(this.selected_file).then(res=>this.url=res)
      this.url = resFile?.fileurl;
    },

    async triggerUpload(file) {
      try {
        // const apiUrl = process.env.VUE_APP_API_URL ?? 'http://localhost:8084';
        const formData = new FormData();
        formData.append('Company_logo', file);
        const response = await axios.post(`${this.apiUrl}/api/Signup/TempDocument`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.uploadStatus = 'Logo uploaded successfully.';
        return response.data.files.Company_logo;
      } catch (error) {
        console.error('Error uploading file:', error);
        this.uploadStatus = 'Error uploading logo.';
      }
    },

    setPlace(place) {
      this.businessInfo.Head_office_address = place.formatted_address;
      this.businessInfo.Head_office_lat = place.geometry.location.lat();
      this.businessInfo.Head_office_long = place.geometry.location.lng();
      this.iscalled = false;
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      // this.getmapImages(marker).then((response) => {
      //   this.google_image = response.data.data
      // })
      this.markers[0] = { position: marker };
      this.center = marker;
    },
    setPlaceBranch(place, index) {
      this.branchOffices[index].Office_address = place.formatted_address;
      this.branchOffices[index].Office_lat = place.geometry.location.lat();
      this.branchOffices[index].Office_long = place.geometry.location.lat();
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.branchOffices[index].markers[0] = { position: marker };
      this.branchOffices[index].center = marker;
    },
    updateCoordinates(place) {
      this.getTown(place.latLng.lat(), place.latLng.lng());
    },
    getTown: function (lat, lng) {
      var latlng = new google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
        }
        if (status == google.maps.GeocoderStatus.OK) {
          var address = results[0].formatted_address;
          this.$refs.head_office.$el.value = address;
          this.businessInfo.Head_office_address = address;
          this.businessInfo.Head_office_lat = lat;
          this.businessInfo.Head_office_long = lng;
        }
      });
    },
    updateCoordinatesbranch(place, index) {
      let lat = place.latLng.lat();
      let lng = place.latLng.lng();
      var latlng = new google.maps.LatLng(lat, lng);
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
        }
        if (status == google.maps.GeocoderStatus.OK) {
          var address = results[0].formatted_address;
          this.$refs.branch_office[index].$el.value = address;
          this.branchOffices[index].Office_address = address;
          this.branchOffices[index].Office_lat = lat;
          this.branchOffices[index].Office_long = lng;
        }
      });
    },
    add_country(d) {
      let results = this.selected_country.find((o) => o.id === d.id);

      if (results || !d || String(d).trim().toLowerCase() === 'select country') {
        return;
      }

      this.did_add_country = true;
      this.selected_country.push(d);
      this.selected_bank_country(this.selected_country[0]);

      // console.info(this.selected_country, this.did_add_country)
    },
    selected_bank_country(e) {
      // this.iban_length = e?.iban_length ?? '32';
      this.businessInfo.bank_iban = !!this.businessInfo?.bank_iban ? this.businessInfo.bank_iban: (e?.code ?? '');
      this.selectedBankCountry = e?.code ?? '';
    },
    removeCArray(index) {
      this.selected_country.splice(index, 1);
      this.selected_bank_country(this.selected_country[0]);
    },
    usePlace() {
      this.businessInfo.Head_office_address = this.$refs.head_office.$el.value;
      this.businessInfo.Head_office_lat = '0.0';
      this.businessInfo.Head_office_long = '0.0';
      console.log(this.businessInfo, 'adress');
      this.iscalled = true;
    },
    usePlaceBranch() {
      for (let x in this.$refs.branch_office) {
        this.branchOffices[x].Office_address = this.$refs.branch_office[x].$el.value;
        this.branchOffices[x].Office_lat = '0.0';
        this.branchOffices[x].Office_long = '0.0';
      }
    },
  },
  created() {
    this.businessInfo = this.signUpForm?.RegistrationD[0];

    if (this.getCompanyLogo) {
      // this.selected_file= this.getCompanyLogo;
      // this.url = URL.createObjectURL(this.getCompanyLogo);
    }
    this.selected_country = this.getSelectedCountry;
    this.businessInfo.Social_media_url1 ? this.socialGroup.push({ url: this.businessInfo.Social_media_url1 }) : '';
    this.businessInfo.Social_media_url2 ? this.socialGroup.push({ url: this.businessInfo.Social_media_url2 }) : '';
    this.businessInfo.Social_media_url3 ? this.socialGroup.push({ url: this.businessInfo.Social_media_url3 }) : '';
    this.businessInfo.Social_media_url4 ? this.socialGroup.push({ url: this.businessInfo.Social_media_url4 }) : '';
    this.businessInfo.Social_media_url5 ? this.socialGroup.push({ url: this.businessInfo.Social_media_url5 }) : '';
    this.businessInfo.Social_media_url6 ? this.socialGroup.push({ url: this.businessInfo.Social_media_url6 }) : '';

    if (this.signUpForm.organization_name) {
      this.businessInfo.Company_name = this.signUpForm.organization_name;
    }
  },
  mounted() {
    // console.info('Mounted bi', this);

    if (this.$store.state.signup.user_id) {
      this.switch_show_components = true;
    }

    this.$refs.observerbusiness.reset();
    this.setCountry();

    setTimeout(() => {
      this.selected_country = [];
      const Country_ids = Array.isArray(this.businessInfo.Country_id)
        ? this.businessInfo.Country_id
        : [this.businessInfo.Country_id];

      for (let x of Country_ids) {
        let val = [];
        val = this.getCoutryData.filter((o) => o.id == x);

        if (val.length > 0) {
          this.selected_country.push(val[0]);
          // this.selected_bank_country(this.selected_country[0])
        }
      }

      if (this.signUpForm.Office_Branches.length) {
        this.branchOffices = this.signUpForm.Office_Branches;
      }

      if (this.businessInfo.Head_office_address) this.$refs.head_office.$el.value = this.businessInfo.Head_office_address;

      if (this.businessInfo.Head_office_lat && this.businessInfo.Head_office_long) {
        const marker = {
          lat: this.businessInfo.Head_office_lat,
          lng: this.businessInfo.Head_office_long,
        };

        this.markers[0] = { position: marker };
        this.center = marker;
      }

      var self = this;
      this.branchOffices.map(function (value, index) {
        self.$refs.branch_office[index].$el.value = value.Office_address;
      });
    }, 1000);

    this.minDate = new Date().toISOString().slice(0, 10);

    if (JSON.parse(localStorage.getItem('vuex')).signup.stepperTitle == 'Regulator Signup') {
      this.show = false;
    }

    let Web_Url = JSON.parse(localStorage.getItem('vuex')).signup.signupForm.Email;

    if (Web_Url) {
      this.businessInfo.Website_url = `http://www.${Web_Url?.substr(Web_Url.indexOf('@') + 1 || '')}`;
    } else {
      this.businessInfo.Website_url = `http://`;
    }

    if (this.getCompanyLogoInfo.url) {
      this.url = this.getCompanyLogoInfo.url;
      this.urlToObject(this.url, this.getCompanyLogoInfo.name);
    }

    // fixed value of https:// with website url
    var input = document.querySelector('input[type="url"]');
    input.addEventListener('keydown', function () {
      var oldVal = this.value;
      var field = this;

      setTimeout(function () {
        if (field.value.indexOf('http://') !== 0) {
          field.value = oldVal;
        }
      }, 1);
    });

    this.FatchWebLogo();

    setTimeout(() => {
      if (this.$store.state.signup.image_path) {
        this.urlToObject(this.$store.state.signup.image_path, this.$store.state.signup.image_name).then((response) => {
          this.fileData.Company_logo = this.selected_file;
          this.$emit('fileUpload', this.fileData);
          this.getBase64(this.selected_file).then((res) => (this.url = res));
        });

        // let count_id = this.$store.state.signup.signupForm.RegistrationD[0].Country_id
        // let num = count_id.substring(0,count_id.length)
        //        let ids = num.split(',')

        //   for (let value of ids){
        //      if(!value) return false;
        //      let results = this.getCoutryData.find((o) => o.id === value);
        //      this.add_country(results)
        //   }
      }
    }, 1000);

    setTimeout(() => {
      this.$refs.head_office.$el.disabled = false;
      this.$refs.head_office.$el.style = '';
      this.$refs.head_office.$el.placeholder = 'Office No, Lane No, Area, City, Country';

      for (let x of this.$refs.branch_office) {
        x.$el.disabled = false;
        x.$el.style = '';
        x.$el.placeholder = 'Office No, Lane No, Area, City, Country';
      }

      if (this.businessInfo.bank_iban == null || this.businessInfo.bank_customer_name == null || this.businessInfo.bank_name == null) {
        this.gonext();
      }
    }, 2000);

    //   end
  },
};
</script>
<style>
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 600px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 200px;
  width: 600px;
}

.mx-datepicker {
  width: 100%;
}
</style>
<style scoped>
.cross-button {
  position: absolute;
  font-weight: bolder;
  right: 11px;
  cursor: pointer;
}
</style>
